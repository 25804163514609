import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
//图标
import { Search } from '@element-plus/icons-vue';
// 弹框
import TagPopup from '../../components/tag/TagPopup.vue';
export default {
  name: "Tag",
  components: {
    TagPopup: TagPopup
  },
  data: function data() {
    return {
      filterTargetTypeDataList: [],
      //筛选后的数组
      tagActivityList: [],
      //标签
      lastClickType: "",
      // list:列表  search：搜索
      currentDetail: {},
      //当前展示的详情
      currentTargetType: "",
      //当前选中的标签维度
      targetTypeDataList: [],
      //标签维度数组
      dialogDetailVisible: false,
      //详情
      searchData: "",
      //search图标
      Search: Search,
      // 用户信息
      userInfo: this.Ls.getParseToken("nof_userInfo"),
      // 筛选
      filter: [{
        placeholder: '标签维度',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '维度1'
        }, {
          id: '2',
          name: '维度2'
        }]
      }, {
        placeholder: '标签状态',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '正常'
        }, {
          id: '-1',
          name: '禁用'
        }]
      }, {
        placeholder: '标签名称',
        value: '',
        type: 'text',
        list: []
      }],
      //左边菜单是否折叠
      isCollapse: false,
      //树形结构数据
      treeData: [],
      //树形结构数据
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      // List接口返回表格数据
      tableKey: 1,
      //表格的key
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 10,
      //每页个数
      // drawer抽屉-数据
      drawer: false,
      //是否显示弹框
      drawerState: '',
      direction: "rtl",
      drawerArticleState: "",
      drawerData: {
        tagtypeId: '',
        name: "",
        description: "",
        rank: "",
        state: 1
      },
      // dialog弹框-删除弹框
      delId: "",
      centerDialogVisible: false,
      // 删除弹框显示隐藏
      localCourse: {
        id: 2
      }
    };
  },
  watch: {
    $route: function $route(newVal, oldVal) {
      //if(newVal.path !== oldVal.path){
      //console.log("监听tag页面的路由",this)
      this.changeRouter();
      //}
    },
    searchData: function searchData(curVal, oldVal) {
      // 实现input连续输入，只发一次请求
      this.filterTypesTag();
    }
  },
  crated: function crated() {},
  mounted: function mounted() {
    // alert("Tag")
    this.initDataFn();
  },
  methods: {
    //点击展开收起
    isCollapseFn: function isCollapseFn() {
      if (this.isCollapse) {
        this.isCollapse = false;
      } else {
        //展开
        this.isCollapse = true;
      }
    },
    //取消详情弹框
    hideDetailPopup: function hideDetailPopup() {
      this.dialogDetailVisible = false;
      this.tagActivityList = [];
    },
    //搜索维度
    filterTypesTag: function filterTypesTag() {
      var _this = this;
      // console.log("搜索维度",this.searchData);
      var filterList = this.targetTypeDataList.filter(function (item) {
        return item.name.indexOf(_this.searchData) > -1;
      });
      this.filterTargetTypeDataList = filterList;
    },
    //删除指定活动
    delActivity: function delActivity(v, k) {
      this.tagActivityList.splice(k, 1);
    },
    //设置标签里的活动
    tagSetTagActivity: function tagSetTagActivity() {
      var _this2 = this;
      console.log(this.tagActivityList);
      this.service.axiosUtils({
        requestName: 'tagSetTagActivity',
        data: {
          // tagtypeId:this.currentDetail.tagtypeId,
          id: this.currentDetail.id,
          activitys: this.tagActivityList
        },
        sucFn: function sucFn(res) {
          // this.tagActivityList=res.data[0].activity;
          if (res.status === 1) {
            _this2.$toast.success(res.message);
            //弹框消失
            _this2.dialogDetailVisible = false;
            //初始化列表
            _this2.initData();
          } else {
            _this2.$toast.error(res.message);
          }
        }
      });
    },
    //获取课程活动标签
    tagGetTagActivity: function tagGetTagActivity() {
      var _this3 = this;
      // console.log("当前活动",this.currentActivity)
      this.service.axiosUtils({
        requestName: 'tagGetTagActivity',
        data: {
          // tagtypeId:this.currentDetail.tagtypeId,
          id: this.currentDetail.id
        },
        sucFn: function sucFn(res) {
          _this3.tagActivityList = res.data[0].activity;
        }
      });
    },
    //获取全部维度
    changeTargetType: function changeTargetType(value) {
      //判断下是不是搜索
      // console.log("点击左边",value.id,this.currentTargetType.id)
      /*      if(this.currentTargetType.id){
      
            }*/
      if (!this.currentTargetType.id && this.lastClickType != this.currentTargetType.id) {
        this.currentTargetType = value;
        this.filter[0].value = "";
        this.filter[1].value = "";
        this.filter[2].value = "";
        this.initData();
      } else {
        this.currentTargetType = value;
        this.$router.push({
          path: this.$route.path,
          name: this.$route.name,
          query: {
            currentTargetType: JSON.stringify(this.currentTargetType)
          }
        });
      }
    },
    //显示详情
    showDetail: function showDetail(data) {
      this.dialogDetailVisible = true;
      this.currentDetail = data;
      //获取详情
      this.tagGetTagActivity();
    },
    // dialog弹框-删除弹框确定函数
    confirmModuleFun: function confirmModuleFun(type) {
      var _this4 = this;
      var requestName = "";
      var postData = {};
      //删除模块
      requestName = "tagDeleteTag";
      postData.id = this.delModuleId;
      postData.contextType = 1;
      postData.courseId = this.$route.query.courseId;
      postData.baseId = this.delModuleBaseId;
      //删除当前的资源类型
      this.service.axiosUtils({
        requestName: requestName,
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this4.$toast.success(res.message);
            _this4.delId = "";
            _this4.centerDialogVisible = false;
            _this4.centerDialogVisibleModule = false;
            _this4.initData();
          } else {
            _this4.$toast.error(res.message);
          }
        }
      });
    },
    // ========================= 初始化函数 start=======================
    initDataFn: function initDataFn() {
      //设置面包屑
      // this.$emit('navigation',this.navData)
      this.getAllFun();
    },
    //获取所有维度
    getAllFun: function getAllFun() {
      var _this5 = this;
      this.service.axiosUtils({
        requestName: 'tagtypeGetAll',
        data: {},
        sucFn: function sucFn(res) {
          _this5.targetTypeDataList = res.data;
          _this5.filterTargetTypeDataList = res.data;
          _this5.filter[0].list = _this5.targetTypeDataList;
          if (_this5.$route.query.currentTargetType) {
            _this5.currentTargetType = JSON.parse(_this5.$route.query.currentTargetType);
          } else {
            _this5.currentTargetType = res.data[0];
          }
          _this5.initData();
        }
      });
    },
    //给其他组件使用的设置当前的tree
    setCurrentTree: function setCurrentTree(value) {
      this.currentTree = value;
    },
    //路由改变执行的方法
    changeRouter: function changeRouter(json) {
      this.filter[0].value = "";
      this.filter[1].value = "";
      this.filter[2].value = "";

      // if(this.$route.path !== '/objective/keyExp') return;

      // this.filter[1].value=this.$route.query.resName || "";
      // this.filter[0].value=this.currentTargetType.id || "";
      // this.filter[0].name=this.currentTargetType.name || "";

      /*  this.filterJson={
          resName:this.filter[1].value,
          state:this.filter[0].value
        }*/
      this.initData();
    },
    // 初始化数据
    initData: function initData() {
      var _this6 = this;
      if (!this.currentTargetType.id) return;
      this.lastClickType = this.currentTargetType.id;
      this.service.axiosUtils({
        requestName: "tagGetList",
        data: {
          pageSize: this.pageSize,
          pageIndex: this.currentPage,
          tagtypeId: this.currentTargetType.id,
          name: this.filter[2].value,
          state: this.filter[1].value
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this6.tableData = res.data.data;
            _this6.total = res.data.total;
          } else {
            _this6.$toast.error(res.message);
          }
        }
      });
    },
    //筛选功能
    search: function search(filterArr) {
      var _this7 = this;
      console.log(this.filter[0].value);
      if (this.filter[0].value) {
        //如果选择了维度
        for (var i = 0; i < this.targetTypeDataList.length; i++) {
          // console.log("是否相等",this.targetTypeDataList[i].id,this.filter[0].value)
          if (this.targetTypeDataList[i].id == this.filter[0].value) {
            this.currentTargetType = this.targetTypeDataList[i];
            break;
          }
        }
      } else {
        this.currentTargetType = {};
      }
      /* this.$router.push({
         path:this.$route.path,
         name:this.$route.name,
         query:{
           currentTargetType:JSON.stringify(this.currentTargetType)
         }
       })*/
      this.lastClickType = this.filter[0].value;
      var targetTypeJson = this.$route.query.currentTargetType || "{}";
      this.service.axiosUtils({
        requestName: "tagGetList",
        data: {
          pageSize: this.pageSize,
          pageIndex: this.currentPage,
          filterTagtypeId: this.filter[0].value || 0,
          tagtypeId: JSON.parse(targetTypeJson).id,
          name: this.filter[2].value,
          state: this.filter[1].value
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this7.tableData = res.data.data;
            _this7.total = res.data.total;
          } else {
            _this7.$toast.error(res.message);
          }
        }
      });
    },
    //重置
    reload: function reload() {
      this.filter[0].value = "";
      this.filter[1].value = "";
      this.filter[2].value = "";
      this.search();
    },
    // page-页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initData();
    },
    // page-页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initData();
    },
    //新建初始化数据
    clearDrawerData: function clearDrawerData() {
      this.drawerData = {
        tagtypeId: '',
        name: "",
        description: "",
        rank: "",
        state: 1
      };
      this.$refs.form.resetFields();
    },
    //  =================== 添加/编辑 抽屉 相关函数  ===================
    // 添加函数
    addFun: function addFun() {
      this.drawerData = {
        tagtypeId: '',
        name: "",
        description: "",
        rank: "",
        state: 1
      };
      this.drawerData.tagtypeId = this.currentTargetType.id;
      this.drawerState = "add";
      this.drawer = true;
      // this.drawerData.areaName = this.currentTree.name
      // this.drawerData.areaId = this.currentTree.id
    },

    // table-中操作函数
    detail: function detail(type, val) {
      if (type === "edit") {
        this.drawer = true;
        this.drawerState = "edit";
        this.drawerData = {
          tagtypeId: val.tagtype && val.tagtype.id,
          name: val.name,
          description: val.description,
          rank: val.rank,
          state: val.state
        };
        this.drawerData.id = val.id;
      } else if (type === "del") {
        this.centerDialogVisible = true;
        this.delId = val.id;
      } else {}
    },
    confirmClick: function confirmClick(formName) {
      var that = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var url = "";
          if (that.drawerState === "edit") {
            //修改
            url = "tagEditTag";
          } else {
            //添加
            url = "tagAddTag";
          }
          that.service.axiosUtils({
            requestName: url,
            data: that.drawerData,
            sucFn: function sucFn(res) {
              if (res.status === 1) {
                that.$toast.success(res.message);
                that.drawer = false;
                that.clearDrawerData();
                if (that.drawerState === "add") {
                  that.currentPage = 1;
                }
                that.initData();
              } else {
                that.$toast.error(res.message);
              }
            }
          });
        }
      });
    },
    cancelClick: function cancelClick() {
      this.drawer = false;
      this.drawerState = "";
      this.clearDrawerData();
    },
    //====================== table 操作函数 =======================
    // table-修改正常/禁用状态
    editState: function editState(row) {
      var _this8 = this;
      var rowState = "";
      if (row.state === 1) {
        rowState = -1;
      } else {
        rowState = 1;
      }
      this.service.axiosUtils({
        requestName: "tagChangeState",
        data: {
          id: row.id,
          state: rowState
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this8.$toast.success(res.message);
            if (row.state === 1) row.state = -1;else row.state = 1;
          } else {
            _this8.$toast.error(res.message);
          }
        }
      });
    },
    // dialog弹框-删除弹框确定函数
    confirmFun: function confirmFun() {
      var _this9 = this;
      //删除当前的资源类型
      this.service.axiosUtils({
        requestName: "tagDeleteTag",
        data: {
          id: this.delId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this9.$toast.success(res.message);
            _this9.delId = "";
            _this9.centerDialogVisible = false;
            _this9.initData();
          } else {
            _this9.$toast.error(res.message);
          }
        }
      });
    }
  }
};